<template>
	<v-lazy :min-height="200" :options="{ threshold: 0.5 }" transition="fade-transition">
		<v-card :to="generateHref(item)" class="blog-card overflow-hidden hover-card center-card">
			<NuxtImg v-show="!hideChips" loading="lazy" format="webp" quality="75" class="elevation-1" :alt="item.title" :src="item.thumbnail" height="280" width="100%" fit="cover" />
			<NuxtImg v-show="hideChips" loading="lazy" format="webp" quality="75" class="elevation-1" :alt="item.title" :src="item.thumbnail" height="140" width="100%" fit="cover" />
			<div class="date-badge text-small bg-danger-grediant">
				<span>{{ calculatePrice(item.ratePlan, currency, locale) }}</span>
			</div>

			<h4 class="font-weight-medium font-16 ma-2">
				{{ item.title }}
			</h4>
			<!-- <div class="badge-operator bg-info-grediant">
			<span
				>Operated by <strong>{{ item.serviceProvider.name }}</strong></span
			>
		</div> -->
			<template v-if="!hideChips">
				<ClientOnly>
					<div class="text-start">
						<v-chip color="blue" density="compact" class="ma-1" v-if="bestSellerCode.includes(item.code)"> Bestseller </v-chip>
						<v-chip color="amber" density="compact" class="ma-1" v-if="bestSellerCode.includes(item.code)"> Recommended by Travellers </v-chip>
						<v-chip :color="getColor()" density="compact" class="ma-1" v-for="chip in generateChips(item)" :key="chip">
							{{ chip }}
						</v-chip>
					</div>
					<div class="text-start">
						<v-chip prepend-icon="mdi-star" class="ma-1" color="orange" density="compact"> {{ parseFloat(Math.random() + 4).toFixed(1) }} ( {{ item.reviewCount }}) </v-chip>
						<v-chip prepend-icon="mdi-clock" class="ma-1" color="red" density="compact">
							{{
								item.content.sched.length == 0
									? 'Departs Anytime'
									: `Departs ${item.content.sched.length}
				${item.content.sched.length > 1 ? 'times' : 'time'} daily`
							}}
						</v-chip>
					</div>
				</ClientOnly>
			</template>
		</v-card>
	</v-lazy>
</template>
<script setup>
import { useCurrencyStore } from '~/stores/currencyStore';
import { storeToRefs } from 'pinia';
const store = useCurrencyStore();
const { currency, locale, multipliers } = storeToRefs(store);
const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	pax: {
		type: Number,
		required: false,
		default: null,
	},
	hideChips: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const calculatePrice = (ratePlan, currency, locale) => {
	let price = `${formatCurrency(ratePlan.base, currency, locale)}`;
	const { base, inc, extra } = ratePlan;
	const pax = props.pax;
	if (pax !== null) {
		const rate = pax <= inc ? base : base + (pax - inc) * extra;
		price = `${pax} ${pax > 1 ? 'person' : 'persons'} starts ${formatCurrency(rate, currency, locale)}`;
	}
	return price;
};

const generateHref = (item) => {
	const transfersType = ['PRIVATE_TRANSFER', 'SHARED_TRANSFER', 'PRIVATE_TRANSFER_WITH_TOUR'];
	return transfersType.includes(item.listing_type) ? `/transfers/${item.slug}` : `/tours/${item.slug}`;
};
const colors = ['indigo', 'success', 'warning', 'error', 'info', 'teal', 'primary', 'green', 'orange', 'blue'];
const getColor = () => colors[Math.floor(Math.random() * colors.length)];
const bestSellerCode = ['PV-PPS-ELN', 'PV-PPUR-PPS-ELN', 'PV-ELN-PPS', 'PV-PPUR-ELN-PPS', 'PV-PPS-PBT', 'PV-PBT-ELN', 'SV-PPS-ELN', 'SV-ELN-PPS'];
const generateChips = (item) => {
	const { listing_type, title } = item;
	const words = title.toLowerCase().split(' ');
	const chips = [];
	if (words.includes('private') && listing_type === 'PRIVATE_TRANSFER') {
		chips.push('Private Transfer');
		chips.push('Minivan');
	}
	if (words.includes('shared') && listing_type === 'SHARED_TRANSFER') {
		chips.push('Shared Transfer');
		chips.push('Minivan');
		chips.push('Budget-friendly');
		chips.push("Backpacker's Choice");
	}
	if (words.includes('underground')) {
		chips.push('Private Transfer');
		chips.push('Guided Tour');
		chips.push('Underground River Tour');
	}
	return chips;
};
</script>
<style scoped>
.title {
	word-break: break-all;
	white-space: break-spaces;
	font-size: 1rem;
	line-height: 1rem;
}

.badge-left {
	left: 10px !important;
}

.date-badge {
	padding: 5px !important;
}

.date-badge span {
	font-size: 12px !important;
}

.badge-operator span {
	font-size: 12px !important;
	color: white;
}

.badge-operator {
	padding: 5px;
}

.hover-card:hover {
	transform: scale(1.05);
}

img {
	object-fit: cover;
	width: 100%;
}
</style>
